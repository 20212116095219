// ---------------------------
// donationSuccessfulPage.js |
// ---------------------------
// Stripe checkout sends the user to this page following a successful checkout. Page confirms completion of the process.
// =========================================================================================================================================

// DEPENDENCIES
// ========================================
import React, {useState, useEffect} from 'react'
import {useParams, useSearchParams, Link, useLocation, useNavigate, Navigate, Outlet, useOutletContext} from "react-router-dom";
import axios from 'axios';

// Server URL 
const server = require("../../connections/serverURL");
var serverURL = server.getServer();

// Helmet for Conversion Tracking
import { Helmet } from 'react-helmet';

// COMPONENTS
// ========================================
import Header from '../sub-components/header.jsx'
import Loader from '../sub-components/loader.jsx'
import GoalTracker from "../sub-components/goalTracker.jsx"
import Footer from '../sub-components/footer.jsx'

// ICONS
// ========================================
import charity from '../../assets/charity.svg'

// CONFETTI
// ========================================
import { fireConfetti } from "../sub-components/confetti";

// COMPONENT
// ========================================

function DonationSuccessful(params){

	// validDonation is a state variable to confirm that there is a valid success page 
	// stripeData holding variable for stripe data associated with stripe session
	// readyToNavigate and setReadyToNavigate are triggers that the mongodb update is complete and we can proceed to prettify the url
	const [validDonation, setValidDonation] = useState(false);
	const [stripeData, setStripeData] = useState({});
	const [readyToNavigate, setReadyToNavigate] = useState(false);

	// One-Off Inclusion of a Conversion Tracker for Faith Unity #TODO: If useful, broaden as a feature
	const [faithUnityGTag, setFaithUnityGTag] = useState(false);
	const [conversionGTag, setConversionGTag] = useState("");

	// Check to see if org has a post checkout donation URL
	const [hasPostCheckoutURL, setHasPostCheckoutURL] = useState(false);
	const [postCheckoutURL, setPostCheckoutURL] = useState("");

	// Context Data for retrieving the org data, session data, and load status
	const outletContext = useOutletContext();
  	const sessionID = outletContext.sessionID;
  	const setsessionID = outletContext.setSessionID;
  	const currentDonation = outletContext.currentDonation; // Only for terminal mode
  	const orgID = outletContext.orgID;
  	const orgData = outletContext.orgData;
  	const isLoading = outletContext.isLoading;
  	const setIsLoading = outletContext.setIsLoading;
  	const successfulFetch = outletContext.successfulFetch;
  	const donationComplete = outletContext.donationComplete; 
  	const setDonationComplete = outletContext.setDonationComplete;
  	const terminalMode = outletContext.terminalMode;
  	const terminalReader = outletContext.terminalReader;
  	const terminalLive = outletContext.terminalLive;

	// Retrieve the relevant values from the stripe checkout	
  	let [searchParams, setSearchParams] = useSearchParams();

	let navigate = useNavigate();
	let location = useLocation();

	// Grab the search parameter with session_id from stripe
	var stripeSession = searchParams.get("cs");
	var stripeAccount = searchParams.get("sa");
	var appSession = searchParams.get("as")
 	var donationAmount = searchParams.get('d');
 	var accountStatus = searchParams.get('acs');

 	// console.log("HERES THE STRIPE SESSION:");
 	// console.log(serverURL);
 	// console.log(readyToNavigate);
 	// console.log(stripeSession);
 	// console.log(stripeAccount);
 	// console.log(appSession);
 	// console.log(donationAmount);
 	// console.log(accountStatus);

 	// Controls the display of the loader. Will show so long as data on the org has yet to be retrieved
	useEffect(() => {
		setDonationComplete(true);
		if(typeof orgData.account_id != "undefined") {
			setIsLoading(false);	
		}
	}, [outletContext])

	// Check to see if org has a post checkout donation URL
	useEffect(() => {
		if(!isLoading && orgData.account_theme.post_checkout_url && orgData.account_theme.post_checkout_url.trim() !== "") {
			setHasPostCheckoutURL(true);
			setPostCheckoutURL(orgData.account_theme.post_checkout_url);
		}
		else {
			setPostCheckoutURL("donate.givemasjid.com/" + orgID);
		}
	}, [outletContext])

	useEffect(() => {

	    fireConfetti();

	}, [])

	// Redirect after 20 seconds
  	useEffect(() => {
	    setTimeout(() => {
	      navigate('../')
	    }, 60000)
	  }, [])

	// If orgid is Faith Unity, then include the conversion tracker
	useEffect(() => {

		if(!isLoading) {
			if(orgID.toUpperCase() == "FAITHUNITY") {
				setFaithUnityGTag(true);
				setConversionGTag(" 'AW-11146158289' ");
				console.log("HI")
			}

		}
		
	}, [orgID, orgData])

	// Checks if there is valid data associated with the stripe session. If so -- update the validDonation flag
	useEffect(() => {

		// If we have not yet completed the cycle
		if(!readyToNavigate & stripeSession != "" & appSession != "" & stripeSession != null & appSession != null) {
		 	
		 	var retrieveURL = serverURL + '/checkout/retrieve/?stripesession=' + stripeSession + "&stripeaccount=" + stripeAccount + "&acstatus=" + accountStatus;
		 	// console.log(retrieveURL);

		 	// Get the data and store it in stripeData variable (if it exists)
		 	const fetchData = async() => {
		 		const result = await axios(retrieveURL);
		 		// console.log(result);

		 		// Check if valid stripe session was returned
		 		if(typeof result.data.id != "undefined") {
		 			// console.log("Valid stripe session");
		 			setValidDonation(true);
		 			setStripeData(result.data);
		 		}

				// Otherwise redirect to page not found 
				else {
					// console.log("Redirecting post checkout")
					navigate( { pathname: "/Page-Not-Found"})
				}

		 	}

		 	fetchData();
		}

		else if(terminalMode) {
			setValidDonation(true);
			setReadyToNavigate(true);
		}


	}, [])


 	// If there's a valid donation then proceed to update the mongo records 
	useEffect(() => {

		// Update session object with information collected in stripe  
		if (validDonation && !terminalMode) {

		 	// MongoUpdate URL 
	 		var mongoupdateURLs = []
	 		mongoupdateURLs[0] = serverURL + '/sessions/update/?sessionid=' + appSession + "&updateparameter=app_data.start_times.completion_time&updatevalue=" + Date.now();
	 		mongoupdateURLs[1] = serverURL + '/sessions/update/?sessionid=' + appSession + "&updateparameter=stripe_data.stripe_id&updatevalue=" + stripeSession;
	 		mongoupdateURLs[2] = serverURL + '/sessions/update/?sessionid=' + appSession + "&updateparameter=stripe_data.donor_info.name&updatevalue=" + stripeData.customer_details.name;
	 		mongoupdateURLs[3] = serverURL + '/sessions/update/?sessionid=' + appSession + "&updateparameter=stripe_data.donor_info.email&updatevalue=" + stripeData.customer_details.email;
	 		mongoupdateURLs[4] = serverURL + '/sessions/update/?sessionid=' + appSession + "&updateparameter=session_status&updatevalue=successful";
	 		mongoupdateURLs[5] = serverURL + '/sessions/update/?sessionid=' + appSession + "&updateparameter=stripe_data.donation_amount&updatevalue=" + donationAmount;

	 		const mongoRun = async() => {
	 			for(const url of mongoupdateURLs) {
			 		const result = await axios(url);
	 			}
	 			setReadyToNavigate(true);

	 		}

	 		mongoRun();

	 	};

	}, [stripeData])

	// Prettify the URL to avoid users from manipulating the donation successful string 
	useEffect(() => {
		if(readyToNavigate) {
			navigate( { search: `?d=${donationAmount}` }, {replace: true})
		}

	}, [readyToNavigate])

	// Format the Currency
	var formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

	// // If the app has yet to pull the org data, display the loader.
	if(isLoading == true) {

		return(

			<div className="h-full min-h-screen lg:min-h-0 flex flex-col ">

				<div className="flex-grow lg:flex-grow-0">
					<div className = " ">
						<Loader className=""/>
					</div>
			 	</div>

		    	{/*Page Footer*/}				
		    	<hr className="mt-4 mb-2 w-11/12 mx-auto"/>
				<Footer/>
			</div>

		);
	}

	else {

		return (

			<div className="flex flex-col h-full">

				{/* Load the GTag for Faith Unity */}
				<Helmet>
					<script async src={`https://www.googletagmanager.com/gtag/js?id=${conversionGTag}`}></script>
					<script>{`
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', ${conversionGTag});
					`}</script>
				</Helmet>

				{/*Page Header*/}
				<Header display_name = {orgData.account_info.display_name} server={serverURL} source={orgData.account_theme.header_image} orgid={orgID}/>

				<div className="">

					{/*Header Line*/}
					<hr className="w-11/12 mx-auto my-4 lg:my-5"/>
					
					{/*Current Donation*/}
					<h2 className="text-base font-bold text-center mb-1 3xl:text-xl 3xl:mb-3">Your Donation</h2>
					<div className="px-4">
				    <input type="text" name="price" id="price" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 text-3xl 3xl:py-3 font-extrabold text-center rounded-full" readOnly value={`${terminalMode ? formatter.format(currentDonation) : formatter.format(donationAmount)}`}></input>
				    </div>

					<hr className="w-11/12 mx-auto my-4 lg:my-5"/>
					<img className = "h-20 text-center mx-auto my-3" src={charity} alt="Icon" />
					<h2 className="text-xl font-bold text-center mb-1">Contribution Received!</h2>
					<p className = "text-center text-lg font-light mb-5 mx-10" >May Allah bless you for your support.</p>
					<hr className="w-11/12 mx-auto my-4 lg:my-5"/>
					<p className = "text-center text-base font-light my-5 mx-5 italic 3xl:text-lg" >Our community is incredibly grateful for your donation and support. You will soon receive an email confirmation for your records. Jazakallahu Khayrun.</p>
				</div>

				<div className="flex-grow">
					<hr className="w-11/12 mx-auto  "/>
					<h1 className = "text-base 3xl:text-xl text-center font-bold mb-1 mt-6">Return to Main Page</h1>
					<div className="mx-auto flex justify-center">
						<a href={postCheckoutURL} ><button className="mt-1 block w-full rounded-full border text-center text-white bg-theme-100 hover:bg-theme-200 active:bg-theme-200 text-base 3xl:text-xl px-6 py-2 disabled:bg-gray-400 disabled:opacity-90"
						>{postCheckoutURL}
						</button></a>
					</div>
					<hr className="w-11/12 mx-auto mt-6 mb-3 hidden 3xl:block"/>
				</div>

				{/*Goal Tracker*/}
				<GoalTracker left={true} title={true} orgID={orgID} orgType={orgData.account_info.org_type} featureEnabled={orgData.account_features.goal_tracker}/>

		    	{/*Page Footer*/}
				<hr className="w-11/12 mx-auto my-4 lg:my-3 "/>
				<Footer/>

			</div>
		)
	}
}


// Export Component
export default DonationSuccessful;



